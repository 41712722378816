$sv-color-neutral: #fff !default;
$sv-color-dark: #505050;

// Primary Color
$sv-color-primary-dark: #00314A !default;
$primary: #005064 !default;
$sv-color-primary90: #1b6274 !default; // special gradient for hovered main navigation only
$sv-color-primary80: #347384 !default;
$sv-color-primary70: #4d8592 !default;
$sv-color-primary60: #6696a2 !default;
$sv-color-primary40: #98b9c0 !default;
$sv-color-primary20: #b4cbd1 !default;
$sv-color-primary10: #cddce1 !default;
$sv-color-primary5: #e6eef0 !default;

// Secondary Color
$secondary: #505050 !default;
$sv-color-secondary80: #505050 !default;
$sv-color-secondary60: #505050 !default;
$sv-color-secondary40: #b4b4b4 !default;
$sv-color-secondary20: #b4b4b4 !default;
$sv-color-secondary10: #b4b4b4 !default;


// Success Color
$success: #66cc99 !default;
$sv-color-success80: #85d6ad !default;
$sv-color-success60: #a3e0c2 !default;
$sv-color-success40: #c2ebd6 !default;
$sv-color-success20: #e0f5eb !default;
$sv-color-success10: #f0faf5 !default;

// Info Color
$info: #005064 !default;
$sv-color-info80: #347384 !default;
$sv-color-info60: #6696a2 !default;
$sv-color-info40: #98b9c0 !default;
$sv-color-info20: #b4cbd1 !default;
$sv-color-info10: #cddce1 !default;

// Warning Color
$warning: #fde2a6 !default;
$sv-color-warning80: #fde8b8 !default;
$sv-color-warning60: #feeeca !default;
$sv-color-warning40: #fef4db !default;
$sv-color-warning20: #fef9ed !default;
$sv-color-warning10: #fffcf6 !default;

// Danger Color
$danger: #ea5674 !default;
$sv-color-danger80: #ee7890 !default;
$sv-color-danger60: #f39aac !default;
$sv-color-danger40: #f7bbc7 !default;
$sv-color-danger20: #fbdde3 !default;
$sv-color-danger10: #fdeef1 !default;

// Light & Dark Bootstrap Definitions
$light: #f9f9f9 !default;
$dark: $sv-color-secondary80 !default;

// grey (screen width selector)
$sv-color-greybase: #002537 !default;
$sv-color-grey18: #D1D8DB !default;
$sv-color-grey15: #D9DFE1 !default;
$sv-color-grey12: #E0E5E7 !default;
$sv-color-grey8: #EBEEEF !default;
$sv-color-grey5: #F2F4F5 !default;
$sv-color-grey2: #F1F1F1 !default;

$error: #d53737 !default;


// Special Navigation Colors: Main Navigation
$sv-color-main-nav-bg: $sv-color-neutral !default;
$sv-color-main-nav-bg-hover: $sv-color-secondary10 !default;

// Special Navigation Colors: Sub Navigation
$sv-color-sub-nav-bg: #f9f9f9 !default; // lighter color for various backgrounds
$sv-color-sub-nav-bg-hover: $sv-color-secondary10 !default; //
$sv-color-sub-nav-bg-active: $sv-color-primary20 !default;
$sv-color-sub-nav-color-active: $secondary !default;

// Special Colors according to StyleGuide
$sv-color-background-qt: #F8FAFA !default; // background of the question types.
$sv-color-background-btn: #EEF2F2 !default; // button color
$sv-color-background-btn-light: #F9F9F9 !default; // button color with dark background


$sv-color-select-bg: #d9dfe1 !default;
$sv-color-select: $sv-color-dark !default;
$sv-color-select-border: solid 1px $sv-color-dark;
$sv-color-select-bg-active : #005064 !default;
$sc-color-select-active: $sv-color-neutral !default;
$sv-color-select-bg-hover: #f5f5f5 !default;
$sv-color-select-hover: $sv-color-dark !default;
$custom-control-indicator-disabled-bg: #e9ecef;

$sv-color-da-first: #004b71;
$sv-color-da-second: #66cc99;
$sv-color-da-third: #33787c;
$sv-color-da-fourth: #8D7257;
$sv-color-da-fifth: #ED982A;
$sv-color-da-sixth: #F9F871;

$sv-color-da-bad: #ee7890;
$sv-color-da-neutral: #fde8b8;
$sv-color-da-good: #85d6ad;

$sv-bg-color-body1: #efefef;
$sv-bg-color-body2: #cdcdcd;