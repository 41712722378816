#logo {
	height: 40px;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background: url(images/logo.svg) no-repeat;
	background-size: contain;
	width: 112px;
	/* Width of new image */
	height: 40px;
	/* Height of new image */
	padding-left: 112px;
	/* Equal to width of new image */
	background-position: center;
	background-color: transparent !important;
  }
  
  img[alt="Survalyzer NG Logo"] {
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background: url(images/logo-login.svg) no-repeat;
	background-size: contain;
	width: 300px;
	/* Width of new image */
	height: 108px;
	/* Height of new image */
	padding-left: 300px;
	/* Equal to width of new image */
	background-position: center;
  }
  
div.da-login-container .da-login-logo {
	background: url(images/logo-login.svg) no-repeat;
	background-size: contain;
	background-position: center center;
  }
body.da-body-login {
	scroll-behavior: smooth;
		  background-image: url(https://survalyzereu.blob.core.windows.net/files/evolution/R2U%20Umgebung/Images/Landingpage-Portal/gelber-bogen.svg?sv=2021-10-04&se=2122-12-20T00%3A00%3A00Z&sr=b&sp=r&sig=JtrygAMdkp30G7907nDt9HO5bUvuKQbyt7mPp4BuueE%3D)!important;
		  background-repeat: no-repeat;
	background-size: 380px;
	background-attachment: fixed;
	background-position: bottom right;
  }
  
  .da-sidebar-logo img {
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background: url(images/logo.svg) no-repeat;
	background-size: contain;
	width: 300px;
	/* Width of new image */
	height: 108px;
	/* Height of new image */
	padding-left: 300px;
	/* Equal to width of new image */
	background-position: center;
  }

/**** anpassungen wizard ****/

h4 {
	margin-top: 30px!important;
    margin-bottom: 0.5rem!important;
    box-sizing: border-box;
    font-size: 1.5rem!important;
    font-weight: 500!important;
    line-height: 1.2!important;
}

app-design-element, sha-interview-page-item-renderer {
	padding-right: 0px!important;
}

#ikkui {
	display: none!important;
}

.sv-process-close .fa-times:before {
	color: $primary;
}

.sv-process-close {
	top: 0.5rem!important;
    right: 1rem!important;
}

.sv-process-container .sv-process-step.sv-process-step-active .sv-process-step-text {
	background-color: $secondary!important;
}

.sv-process-container .sv-process-step.sv-process-step-success .sv-process-step-text {
	background-color: $secondary!important;
}

.sv-process-container .sv-process-step.sv-process-step-active .sv-process-step-arrow:after {
	border-left-color: $secondary!important;
}

.sv-process-container .sv-process-step.sv-process-step-success .sv-process-step-arrow:after {
	border-left-color: $secondary!important;
}

.sv-process-sticky-steps {
	margin-top:0px!important;
	box-shadow: 0px 5px 10px #0000001f;
}

.bg-primary, .sv-process-fixed-footer {
    background-color: $primary !important;
    background: $sv-color-primary80 !important;
    background: linear-gradient(90deg, $sv-color-primary80 0%, $primary 100%) 	!important;
    box-shadow: 0 10px 60px 6px rgb(0 0 0 / 25%);}

.fixed-bottom, .sv-process-fixed-footer {
	z-index: 20;
}

.stickyWizard {
	overflow: visible;
}

.sv-process-fixed-footer-container {
 justify-content: flex-end!important;
}

.sv-process-nxt-btn, .sv-process-back-btn {
	background-color: $sv-color-neutral !important;
	color:$primary!important;
    min-width: 110px;
	border-color: transparent!important;
}

.sv-process-nxt-btn:hover, .sv-process-back-btn:hover {
	background-color: $sv-color-primary10 !important;
	color:$primary!important;
    min-width: 110px;
	font-weight: 400;
	border-color: transparent!important;
}

.sv-process-nxt-btn:active {
	background-color: $sv-color-neutral!important;
	color:$primary!important;
	border-color: transparent!important;
}

.sv-process-nxt-btn:focus {
	background-color: $sv-color-neutral;
	box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%)!important;
}

.sv-process-nxt-btn:disabled {
	background-color: $secondary!important;
	color:$sv-color-neutral!important;
	border-color: transparent!important;
	box-shadow: none;
	cursor: not-allowed;
}

.btn-success {
	border-radius: 0.15rem;
    /* padding: 15px; */
    background-color: $primary;
	border-color: transparent;
	font-weight: 700;
	font-size: 16px;
	}

.btn-success:focus {
	border-radius: 0.15rem;
    /* padding: 15px; */
    background-color: $primary;
	border-color: transparent;
	font-weight: 700;
	font-size: 16px;
	}

.btn-success:not(:disabled):not(.disabled):active {
	border-radius: 0.15rem;
    /* padding: 15px; */
    background-color: $primary;
	border-color: transparent;
	font-weight: 700;
	font-size: 16px;
	}

.btn-success:visited {
	border-radius: 0.15rem;
    /* padding: 15px; */
    background-color: $primary;
	border-color: transparent;
	font-weight: 700;
	font-size: 16px;
	}


.btn-success:hover {
    background-color: $sv-color-primary70;
	border-color: transparent;
	}

.sv-success-block:before {
	color:$primary;
}

.sv-information-block, .sv-danger-block, .sv-success-block, .sv-info-block, .sv-warning-block {
	border: 1px solid $primary;
	border-radius: 10px;
}

.sv-process-container .sv-process-step .sv-process-step-number {
	margin-right: 0.75rem!important;
	font-weight: 700;
	background-color: $secondary!important
}

.sv-process-container .sv-process-step.sv-process-step-success .sv-process-step-number {
	background-color: $sv-color-neutral!important;
	color: $secondary!important;
}

.sv-process-container .sv-process-step.sv-process-step-active .sv-process-step-number {
	background-color: $sv-color-neutral!important;
	color: $secondary!important;
}

.sv-process-container .sv-process-step.sv-process-step-active .sv-process-step-label {
	font-weight: 700;
}


.sv-btn-primary, a.sv-btn-primary:not([href]):not([tabindex]) {
	border-radius: 0.15rem;
    /* padding: 15px; */
    background-color: $primary;
	border-color: transparent;
	margin-bottom: 15px;
	}

.sv-btn-primary, a.sv-btn-primary:not([href]):not([tabindex]):hover {
    background-color: $sv-color-primary70;
		border-color: transparent;
	}

.rt-container .rt-control-input ~ .rt-control-label:before {
	border-color: $primary!important;
}

#memberUpload {
	padding-bottom: 150px;
}

.member-upload-table {
	border-radius:10px;
	padding:30px;
	box-shadow:none;
}

.member-upload-table-init:hover {
	border: 1px solid $primary;
}

.member-upload-container {
	border: 1px solid $primary;
	border-radius:10px;
	height: auto;
}

.member-upload-table table thead {
	font-size: 18px;
    color: $primary;
}

.member-upload-table {
	border:none;
}

#manage-participants-button-validate {
	margin-bottom: 10px
}
.fr-view table td, .fr-view table th {
	border: none;
	/* border-bottom: 1px dotted #80a8b2; */
}

body {
	scroll-behavior: smooth;
		  background-image: url(https://survalyzereu.blob.core.windows.net/files/evolution/R2U%20Umgebung/Images/Landingpage-Portal/gelber-bogen.svg?sv=2021-10-04&se=2122-12-20T00%3A00%3A00Z&sr=b&sp=r&sig=JtrygAMdkp30G7907nDt9HO5bUvuKQbyt7mPp4BuueE%3D)!important;
		  background-repeat: no-repeat;
	background-size: 380px;
	background-attachment: fixed;
	background-position: bottom right;
} 


.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon, .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  color: $sv-color-neutral!important;
  background-color: $primary!important;
  }
  .dx-dropdowneditor-icon {
  color:$sv-color-neutral!important;
  background-color: $primary!important;
  }
  .navbar-brand .fa-times:before {
	color:$sv-color-neutral;
	}
	
	.navbar, .dropdown-menu-right {
		font-family: arial;
		font-size: 14px;
		color: #000;
	}
	
	.navbar-light .navbar-nav .nav-link {
	Color:#000;
	}
	
	.navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, 0.7);
	}

	.pt-tiles-list .pt-tile .pt-tile-footer .pt-tile-footer-btn-cta {
		font-weight: normal;
	}


		
	